<template>
  <div
    class="box is-flex is-flex-direction-column is-justify-content-center"
    :class="[
      isListing ? 'has-left-border has-background-white-ter' : 'has-border'
    ]"
  >
    <div class="columns is-vcentered is-multiline">
      <div
        class="column is-7-tablet is-12-mobile"
        :class="[isYoungUsed ? 'is-6-widescreen' : 'is-8-widescreen']"
      >
        <div class="columns is-mobile is-multiline is-vcentered">
          <div class="column vrm-column is-narrow-mobile">
            <button
              class="button is-size-7-mobile button-extras"
              :class="[
                { 'is-loading': $wait.is(`loading-${vrm}`) },
                { 'is-disabled has-text-dark has-background-white': isListing }
              ]"
              :disabled="$wait.is('loading-*')"
              @click="search"
              :title="[isListing ? '' : 'View vehicle']"
              v-tippy
            >
              {{ vehicle.vrm }}
            </button>
          </div>
          <div class="column year-column is-narrow-mobile">
            <span class="icon is-medium has-text-dark">{{ vehicle.year }}</span>
          </div>
          <div
            v-if="vehicle.colour"
            class="column colour-column is-narrow-mobile"
          >
            <span
              v-if="vehicle.colour"
              class="icon has-text-soft"
              :title="[$mq === 'mobile' ? startCase(vehicle.colour) : '']"
              v-tippy
            >
              <i class="fas fa-paint-brush" />
            </span>
            <span
              v-if="!isYoungUsed"
              class="is-hidden-mobile has-text-dark"
            > {{ startCase(vehicle.colour) }}
            </span>
          </div>
          <div
            v-if="vehicle.dealer"
            class="column dealer-column is-narrow-mobile"
          >
            <span
              v-if="vehicle.dealer"
              class="icon has-text-soft"
              :title="[$mq === 'mobile' ? vehicle.dealer : '']"
              v-tippy
            >
              <i
                :class="
                  `fas ${
                    vehicle.dealer === 'Private' ? 'fa-home' : 'fa-building'
                  }`
                "
              />
            </span>
            <span
              v-if="!isYoungUsed"
              class="is-hidden-mobile has-text-dark"
            >
              {{ vehicle.dealer }}
            </span>
          </div>
          <div
            v-if="listing.adText && keyword.length > 0"
            class="column is-narrow"
          >
            <a
              v-if="listing.adText && keyword.length > 0"
              class="icon has-text-info"
              :title="excerpt(listing.adText, keyword)"
              v-tippy
              @click="openAdText"
            >
              <i class="fas fa-align-left" />
            </a>
          </div>
        </div>
      </div>
      <div
        v-if="!isYoungUsed"
        class="column is-1 is-hidden-mobile"
      >
        <img
          v-if="vehicle.sold"
          :src="require('core/img/icons/sold.svg')"
          class="is-block"
          alt="Sold"
          width="48"
        >
      </div>
      <div 
        v-if="$mq === 'mobile'"
        class="column is-12 py-0"
      >
        <hr class="hr is-marginless">
      </div>
      <div
        class="column is-4-tablet is-12-mobile"
        :class="[isYoungUsed ? 'is-6-widescreen' : 'is-3-widescreen']"
      >
        <div
          class="columns is-flex is-justify-content-space-between"
          :class="{ 'is-vcentered': $mq !== 'mobile' }"
        >
          <div class="column is-narrow is-narrow-mobile no-wrap">
            <div v-if="vehicle.mileage">
              <p class="is-size-6 has-text-dark">
                <span>{{ vehicle.mileage }}</span>
              </p>
            </div>
          </div>
          <div class="column is-8 is-auto-mobile">
            <div
              v-if="vehicle.sold && $mq === 'mobile'"
              class="column is-narrow is-paddingless is-flex is-justify-content-flex-end"
            >
              <img
                :src="require('core/img/icons/sold.svg')"
                alt="Sold"
                width="48"
              >
            </div>
            <div
              v-if="vehicle.price"
              class="column is-narrow is-paddingless"
            >
              <p class="has-text-right has-text-dark is-flex is-flex-direction-column mb-2">
                <span class="is-size-6 has-text-weight-bold">
                  {{ vehicle.price }}
                </span>
                <span
                  v-if="excVat(listing)"
                  class="is-size-7" 
                >
                  subject to VAT status
                  <i
                    class="ml-1 fal has-text-info fa-info-circle is-size-6 is-clickable"
                    :title="vatTooltip"
                    v-tippy="{ placement: 'right' }"
                  />
                </span>
              </p>
            </div>
            <div class="column is-12 has-text-right is-size-7 is-paddingless">
              <div :class="{ 'has-text-grey': vehicle.sold }">
                <p v-if="vehicle.dealerName">
                  {{ startCase(vehicle.dealerName) }}
                </p>
                <p
                  v-if="vehicle.city"
                  class="no-wrap" 
                >
                  {{ vehicle.city }}
                </p>
                <p
                  v-if="vehicle.soldDate && vehicle.sold" 
                  class="no-wrap"
                >
                  Sold {{ dateFormat(vehicle.soldDate) }}
                </p>
                <p
                  v-if="vehicle.distance"
                  class="icon has-text-soft no-wrap"
                  :title="`${vehicle.distance} miles away`"
                  v-tippy
                >
                  <i class="fal fa-map-marked-alt" />
                </p>
                <p
                  v-if="listing.firstSeen && daysOnSale"
                  class="no-wrap"
                >
                  {{ daysOnSale }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as autocheck from 'modules/autocheck/services'
import { timeAgo, daysDifference } from '@/utils/date.utils.ts'
import { mapGetters } from 'vuex'
import { formatNumber, formatMoney } from 'accounting'
import { startCase } from 'lodash'
import * as companion from 'modules/companion/services'
export default {
  name: 'VehicleListing',
  data: () => ({
    error: null,
    vatTooltip:
      'This price does not include VAT. Its eligibility for VAT qualification depends on specific criteria, which can vary based on factors such as usage and registration.'
  }),
  props: {
    listing: {
      type: Object,
      default: () => null
    },
    isListing: {
      type: Boolean,
      required: false,
      default: false
    },
    isYoungUsed: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      keyword: 'companion/similarListings/filterBy/keywordFilter',
      modalIsActive: 'modal/active',
      dealerIds: 'auth/dealerIds'
    }),
    ...mapGetters('companion', ['vrm', 'year']),
    vehicle() {
      const { listing, vrm, year } = this
      return this.isListing
        ? {
            sold: listing.sold,
            vrm: vrm,
            vin: null,
            year: year,
            dealer: false,
            mileage: `${formatNumber(listing.mileage)} miles`,
            price: formatMoney(this.listing.listingPrice, '£', 0),
            dealerName: listing.dealerName,
            soldDate: listing.soldDate,
            city: false,
            distance: false
          }
        : {
            sold: listing.sold.value,
            vrm: listing.vrm,
            vin: listing.vin,
            colour: listing.colour,
            year: listing.year,
            dealer: listing.dealer.type,
            mileage: listing.mileage.display,
            price: listing.price.display,
            dealerName: listing.dealer.name,
            soldDate: listing.sold.date,
            city: listing.location.city,
            distance: listing.location.distance
          }
    },
    daysOnSale() {
      const lastSeen = this.vehicle.sold
        ? new Date(this.vehicle.soldDate)
        : new Date()
      const diffDays = daysDifference(lastSeen, this.listing.firstSeen) + 1
      return diffDays
        ? `${formatNumber(diffDays)} day${diffDays === 1 ? '' : 's'} on sale`
        : '1 day on sale'
    },
    adText() {
      const keywords = this.flattenKeywords(this.keyword)
      const classes = ' has-text-black has-background-warning'
      return this.highlightKeywords(keywords, this.listing.adText, classes)
    }
  },
  methods: {
    startCase,
    ...companion,
    dateFormat(value) {
      if (value) return timeAgo(value)
    },
    async search() {
      if (this.isListing) return
      this.$mxp.track('click_vehicle_in_similar_listings')
      
      try {
        this.$wait.start(`loading-${this.vehicle.vin ?? this.vehicle.vrm}`)
        const { vin, mileage, vrm } = this.listing

        if (!vin) {
          await companion.value({ vrm, mileage: mileage.value })
        }

        const data = await autocheck.precheck({
          value: vin,
          type: 'vin',
          mileage: mileage.value
        })

        await companion.value({ vrm: data?.vrm ?? vrm, mileage: mileage.value }) 
      } catch (error) {
        this.$mxp.track('error_fetching_vehicle_in_similar_listings', {
          error: error.message
        })
      } finally {
        if (this.modalIsActive) this.$modal.close()
        this.$wait.end(`loading-${this.vehicle.vin ?? this.vehicle.vrm}`)
      }
    },
    excVat({ type }) {
      return type === 'LCV' || type === 'HCV'
    },
    openAdText() {
      this.$modal.open('companion/AdText', this.adText)
      this.$mxp.track('expand_similar_listing_adtext', {
        keyword: this.keyword
      })
    }
  }
}
</script>

<style lang="sass" scoped>
.box
  min-height: 7rem
.no-wrap
  white-space: nowrap
.is-auto-mobile
  flex: auto
.has-border
  border: 1px solid $disabled
.has-left-border
  border-left: solid 0.7rem $info
  padding-left: 0.75rem
</style>
